// Importing indexApp.tsx is done dynamically to allow for the appsettings.json file to be loaded first.
// If import need to be included at top level make sure those imports are not dependent on appsettings.json file.
// It also handles the logic for overriding appsettings based on hostname for MSX environments.

import configManager from 'features/app/config/configManager';
import { DefaultFlightMode, FlightsAppConfig, IAppConfig } from 'features/app/config/configType';

const publicUrl = process.env.PUBLIC_URL || process.env.REACT_APP_PUBLIC_URL || '';

function isLocalUrl() {
	return (
		window.location.hostname === 'local.c3.mepla.microsoft.com' ||
		window.location.hostname === 'localhost' ||
		window.location.hostname === 'msxplatform.crm.dynamics.com'
	);
}

async function initializeAppConfig() {
	try {
		const response = isLocalUrl()
			? await fetch(`${publicUrl}/settings/ms.local.appsettings.json`)
			: await fetch(`${publicUrl}/appsettings.json`);
		if (!response.ok) {
			throw new Error(
				`Failed to fetch proposal workspace appsettings: ${response.status} ${response.statusText}`
			);
		}
		let data = (await response.json()) as IAppConfig;
		data = processData(data);
		configManager.setConfig(data);
	} catch (error) {
		console.error(`Error in proposal workspace - initializeAppConfig: ${error}`);
	}
}

initializeAppConfig().then(async () => {
	await import(/* webpackChunkName: "indexApp" */ 'indexApp');
});

export function processData(data: IAppConfig): IAppConfig {
	// override appsettings based on hostname
	if (data && data.services != null) {
		switch (window.location.hostname) {
			case data.hostnames?.msxLocal:
			case data.hostnames?.msxSupport: {
				data.services.flights = {
					...data.services.flights,
					defaultFlightMode: DefaultFlightMode.msxProd,
				} as FlightsAppConfig;
				break;
			}
			case data.hostnames?.msxDev: {
				data.services.flights = {
					...data.services.flights,
					defaultFlightMode: DefaultFlightMode.msxLatest,
				} as FlightsAppConfig;
				break;
			}
			case data.hostnames?.msxFedSalesDev: {
				data.services.flights = {
					...data.services.flights,
					defaultFlightMode: DefaultFlightMode.FedSalesDev,
				} as FlightsAppConfig;
				if (data.services.quotecenterBackend != null) {
					data.services.quotecenterBackend.environmentOverrides = {
						// we're intentionally targeting the fedsalesdev1 environment to INT Quotes API to facilitate the 100-day demo
						// initiative
						...data.services.quotecenterBackend.environmentOverrides,
						crmfgoeenvironment: 'fedsalesdev1',
					};
				}
				break;
			}
			case data.hostnames?.msxUsxDemo: {
				data.services.flights = {
					...data.services.flights,
					defaultFlightMode: DefaultFlightMode.UsxDemo,
				} as FlightsAppConfig;
				if (data.services.quotecenterBackend != null) {
					data.services.quotecenterBackend.environmentOverrides = {
						// we're intentionally targeting the usxdemo environment to INT Quotes API to facilitate the 100-day demo
						// initiative
						...data.services.quotecenterBackend.environmentOverrides,
						crmfgoeenvironment: 'usxdemo',
					};
				}
				break;
			}
			case data.hostnames?.msxPerf:
			case data.hostnames?.msxPreProd:
			case data.hostnames?.msxSQA:
			case data.hostnames?.msxUAT: {
				data.services.flights = {
					...data.services.flights,
					defaultFlightMode: DefaultFlightMode.msxStable,
				} as FlightsAppConfig;
				break;
			}
			case data.hostnames?.msxProd: {
				data.services.flights = {
					...data.services.flights,
					defaultFlightMode: DefaultFlightMode.msxProd,
				} as FlightsAppConfig;
				break;
			}
		}
	}
	return data;
}
